.app {
  background-color: #F6F7F9;
  /* font-family: 'Roboto', sans-serif; */
}

.privacy__icon:hover .tooltiptext {
    visibility: visible;
    bottom: 100%;
}

.privacy__icon .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

button:focus {
  outline: none;
}


@media only screen and (max-width: 600px) {
  .header_button {
    display: flex;
    flex-direction: column;
  }

  .gutter_bottom {
    margin-bottom: 10px;
  }
}
  
  
  
  
  