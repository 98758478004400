/* p {
    font-weight: bold;
    font-weight: '16px';
}

p > span {
    font-weight: normal;
} */

td:first-child {
    font-weight: bold;
}