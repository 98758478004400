.setting_modal {
  font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    letter-spacing: normal;
  }
  
  .profileUpdate_buttons {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
  }
  
  .profileUpdate_heading:first-child {
    margin-top: 32px;
  }
  
  .profileUpdate_heading {
    margin: 16px 32px;
    text-align: center;
  }
  
  .profileUpdate_heading_ {
    color: #262626;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin: -4px 0 -6px;
  }